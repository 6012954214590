import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { AnimatePresence, motion } from 'framer-motion';
import VimeoIcon from 'images/Shared/Footer/VimeoIcon';
import LinkedInIcon from 'images/Shared/Footer/LinkedinIcon';
import FacebookIcon from 'images/Shared/Footer/FbIcon';
import InstaIcon from 'images/Shared/Footer/InstaIcon';
import { isMobile } from 'react-device-detect';
import {
  CaseStudyPreviews,
  ContactForm,
  ClientLogos,
  ClientVideoCarousel,
  ResponsiveVideo,
  HomePageH1,
  Layout,
  NewsletterSignUp,
  SEO,
  ServiceAnimations,
  SeeOurPortfolio,
  Title,
  HeroText
} from 'components';
import homepageThumbnail from 'images/Home/homepage-thumbnail.jpg';

const IndexPage = ({ location, handleSplashScreen }) => (
  <Layout location={location}>
    <SEO
      pageSpecificTitle="Get Broadcasting"
      pageSpecificSiteTitle="Trusted Corporate Events & Video Specialists"
      pageSpecificThumbnail={homepageThumbnail}
      pathname={location.pathname}
    />
    <ResponsiveVideo
      hideControls
      pathname={location.pathname}
      videoTitle="home_video"
      handleSplashScreen={handleSplashScreen}
    />
    <ServiceAnimations />
    <Section>
      {!isMobile && (
        <Socials>
          <p>
            Follow
            <br />
            us
          </p>
          <SocialMediaButton href="https://vimeo.com/showcase/getbroadcasting/" target="_blank">
            <VimeoIcon />
          </SocialMediaButton>
          <SocialMediaButton href="https://www.facebook.com/GetBroadcastingStudios" target="_blank">
            <FacebookIcon />
          </SocialMediaButton>
          <SocialMediaButton
            href="https://www.linkedin.com/company/get-broadcasting/"
            target="_blank">
            <LinkedInIcon />
          </SocialMediaButton>
          <SocialMediaButton href="https://www.instagram.com/get_broadcasting/" target="_blank">
            <InstaIcon />
          </SocialMediaButton>
          <p>
            on
            <br />
            Social
          </p>
        </Socials>
      )}
      <Title text="What We Do" style={{ marginTop: '2.3em' }} />
      <HeroText>
        Offering <strong>Broadcast as a Service</strong>, from{' '}
        <strong>Hybrid & Virtual Events</strong> like Webinars and Conferences, to curating and
        creating bespoke <strong>Video & Animation</strong> projects, as well as Audio Engineering,
        Social Media Content and Content Strategy, <strong>3D Virtual Studios</strong> and{' '}
        <strong>in-house Creative Design</strong>.
      </HeroText>
    </Section>
    <Title text="Our Work" />
    <CaseStudyPreviews />
    {/* <SeeOurPortfolio /> */}
    <Title text="Clients" />
    <ClientVideoCarousel handleSplashScreen={handleSplashScreen} />
    <ClientLogos />
    <Title text="Contact Us" />
    <ContactForm />
    {/* <NewsletterSignUp /> */}
  </Layout>
);

const Photo = styled(Img)`
  max-width: 80px;
  @media screen and (min-width: 1024px) {
    max-width: 128px;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Socials = styled.div`
  align-items: center;
  background: linear-gradient(
    157deg,
    rgba(255, 239, 0, 1) 0%,
    rgba(247, 167, 39, 1) 17%,
    rgba(228, 9, 126, 1) 83%
  );
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 78px;
  justify-content: center;
  position: fixed;
  padding: 1.25rem 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  p {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
  }
`;

const SocialMediaButton = styled(motion.a).attrs({
  whileHover: {
    scale: 1.15
  },
  whileTap: {
    scale: 0.85
  }
})`
  border-radius: 50%;
`;

export default IndexPage;
